const config = {
  basename: '',
  defaultPath: '/home',
  API_SERVER: 'https://backend-agendamentos-conecta.meubot.chat',
  //API_SERVER: 'http://localhost:3005',
  storageInfo: {
    key: 'account',
    keyPrefix: 'meubot-'
  },
  routeTypes: {
    user: 'user',
    admin: 'admin',
    guest: 'guest'
  }
};

export default config;
