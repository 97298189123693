import { createStore, combineReducers } from 'redux';
import accountReducer from './accountReducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import config from 'src/config';
import setViewReducer from './setViewReducer';

const reducer = combineReducers({
  account: persistReducer(
    {
      key: config.storageInfo.key,
      storage,
      keyPrefix: config.storageInfo.keyPrefix
    },
    accountReducer
  ),
  set: persistReducer(
    {
      key: 'set',
      storage,
      keyPrefix: 'view'
    },
    setViewReducer
  )
});

const store = createStore(reducer)

const persister = persistStore(store)

export { store, persister }
