import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

// project imports
import config from '../../config';
import { Navigate } from 'react-router-dom/dist';

//-----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const GuestGuard = ({ component: Component, ...rest }) => {
  const account = useSelector((state) => state.account);
  const { isLoggedIn } = account;


  if (isLoggedIn) {
    return <Navigate to={config.defaultPath} />;
  }

  return !isLoggedIn ? (
    <Component {...rest} />
  ) : (
    <Navigate to={config.defaultPath} replace />
  );

};

GuestGuard.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default GuestGuard;
