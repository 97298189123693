// action - account reducer
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE'
export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE'
export const ROCKETCHAT_ACCOUNT_UPDATE = 'ROCKETCHAT_ACCOUNT_UPDATE'
export const SET_AVATAR_URL = 'SET_AVATAR_URL'
export const SET_COMPANY_CONFIGURATIONS = 'SET_COMPANY_CONFIGURATIONS'


