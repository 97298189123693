// action - state management
import { ACCOUNT_INITIALIZE, ACCOUNT_UPDATE, LOGIN, LOGOUT, ROCKETCHAT_ACCOUNT_UPDATE, SET_COMPANY_CONFIGURATIONS } from './actions';

export const initialState = {
  token: '',
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  id: null,
  email: null,
  avatar: null,
  phone: null

};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_CONFIGURATIONS: {
      const { url, configs } = action.payload;
      return {
        ...state,
        companyLogoUrl: url,
        configs: configs
      }
    }
    case ACCOUNT_INITIALIZE: {
      const { isLoggedIn, user, token, id, email, avatar, phone } = action.payload;
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        token,
        user,
        id,
        email,
        avatar,
        phone
      };
    }
    case ACCOUNT_UPDATE: {
      const { isLoggedIn, user, token, id, email, avatar, phone } = action.payload;
      const { authToken, server } = action.payload;
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        token,
        user,
        id,
        email,
        avatar,
        phone
      };
    }
    case LOGIN: {
      const { user, token, id, email, avatar, phone } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        token,
        user,
        id,
        email,
        avatar,
        phone
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        token: '',
        user: null,
        id: null,
        email: null,
        avatar: null,
        phone: null

      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
